import React from 'react'

function DetailsHeader() {
    return (
        <div
            className="mt-4 flex details-header w-full items-start justify-between lg:items-center">
            <div className="flex flex-wrap items-center gap-2 lg:gap-4">
                <div
                    className="items-center justify-center py-1 rounded-xl text-white whitespace-nowrap bg_head_gray px-4 text-sm details-flex">ERC20 Pool</div>
                <div
                    className="inline-flex items-center justify-center px-3 py-1 rounded-xl text-white whitespace-nowrap bg_head_gray text-sm">4.413% APR</div>
                <div
                    className="flex items-center justify-center gap-3 px-3 py-1 rounded-xl text-white whitespace-nowrap  bg_head_gray text-sm">
                    <div className="relative w-[20px] h-[20px] mr-3 size-5">
                        <img
                            className="size-full rounded-full bg-white"
                            src="https://assets.smold.app/api/token/1/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo-128.png"
                            width="100%"
                            height="100%"
                            alt="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"/>
                    </div>
                        <span className="whitespace-nowrap text-xs text-white lg:text-sm">1 wstETH = 1.144151 WETH *</span>
                    </div>
                    <span className="whitespace-nowrap text-xs text-gray-100 mb-0">* Based on Lowest Utilized Price</span>
                </div>
                <div className="margin_4 flex items-center ">
                    <span className="text-center text-sm text-gray-100">Pool address</span>
                    <div
                        className="ml-1 flex items-center p-2 font-monospace text-base font-bold text-white">0x3ba6...e5f4<div className="cursor-pointer " data-state="closed">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-2 inline-block cursor-pointer">
                                <path
                                    d="M7 17H5C4.46957 17 3.96086 16.7893 3.58579 16.4142C3.21071 16.0391 3 15.5304 3 15V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V7"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path
                                    d="M19.895 7H8.105C7.49472 7 7 7.49472 7 8.105V19.895C7 20.5053 7.49472 21 8.105 21H19.895C20.5053 21 21 20.5053 21 19.895V8.105C21 7.49472 20.5053 7 19.895 7Z"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default DetailsHeader