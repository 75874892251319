import React from 'react';
// import { Link } from 'react-router-dom';
import './Swap.css';

import HomeCrumb from './sections/HomeCrumb';
import WalletDisplay from './sections/WalletDisplay';
import PoolsMenu from './sections/PoolsMenu';
import GridView from './sections/GridView';

function Swap() {

    return ( 
    <div className='w-full'> 
        <HomeCrumb/>

        <WalletDisplay/>

        <PoolsMenu/>

        <div className='flex flex-col'>
            <GridView/>
            <div className="flex items-center w-full justify-center mt-6">
                <button
                    aria-label="previous"
                    className="p-8 disabled:opacity-50"
                    disabled=""
                    type="button">
                    <svg
                        width="14"
                        height="11"
                        viewBox="0 0 14 11"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M13.5 5.96338C13.5 6.37759 13.1642 6.71338 12.75 6.71338L0.75 6.71338C0.335786 6.71338 -5.08894e-08 6.37759 -3.27835e-08 5.96338C-1.46777e-08 5.54916 0.335786 5.21338 0.75 5.21338L12.75 5.21338C13.1642 5.21338 13.5 5.54917 13.5 5.96338Z"
                            fill="#ffffff"></path>
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.56646 1.14643C5.85936 1.43932 5.85936 1.91419 5.56646 2.20709L1.81022 5.96333L5.56646 9.71957C5.85936 10.0125 5.85936 10.4873 5.56646 10.7802C5.27357 11.0731 4.7987 11.0731 4.5058 10.7802L0.219231 6.49366C-0.073662 6.20077 -0.073662 5.72589 0.219231 5.433L4.5058 1.14643C4.7987 0.853534 5.27357 0.853534 5.56646 1.14643Z"
                            fill="#ffffff"></path>
                    </svg>
                </button>
                <p className=" text-sm font-normal text-primary-500 mb-0">
                    <strong>1 </strong>
                    of 15 pages</p>
                <button aria-label="next" className="p-8 disabled:opacity-50" type="button">
                    <svg
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.5 6H13.5" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                        <path
                            d="M9.5 1.5L13.5 6L9.5 10.5"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"></path>
                    </svg>
                </button>
            </div>
        </div>
        

        
    </div>
    )
}

export default Swap