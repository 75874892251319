import React from 'react'

function DetailsCrumb() {
    return (
        <div className="flex w-full items-center">
            <div className="flex font-light gap-1 md:gap-2 items-center justify-center text-sm">
                <a
                    className="text-center no-underline hover:underline max-w-[80px] md:max-w-[250px] text-[14px] truncate text-primary-200"
                    href="/">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-[-2px]">
                        <path
                            d="M4.16699 17.5V10.8333H1.66699L10.0003 3.33334L18.3337 10.8333H15.8337V17.5H4.16699ZM10.0003 5.575L5.83366 9.325V15.8333H14.167V9.325L10.0003 5.575ZM9.58366 15.8333V12.5H7.50033L10.417 6.66667V10H12.5003L9.58366 15.8333Z"
                            fill="white"></path>
                    </svg>
                </a>
                <span className="text-gray-300">&gt;</span>
                <a
                    className="text-center no-underline hover:underline max-w-[80px] md:max-w-[250px] text-[14px] truncate text-primary-200"
                    href="/">Pools</a>
                <span className="text-gray-300">&gt;</span>
                <a
                    className="text-center no-underline hover:underline max-w-[80px] md:max-w-[250px] text-[14px] truncate font-bold text-gray-400"
                    href="/details/0x3ba6a019ed5541b5f5555d8593080042cf3ae5f4">wstETH / WETH</a>
            </div>
        </div>
    )
}

export default DetailsCrumb