import React from 'react'

function DetailsTitle() {
    return (
        <div className="mt-6 flex w-full items-start justify-between">
            <div className="flex w-full items-center">
                <div className="flex flex-row flex-wrap items-center gap-4">
                    <div className="flex items-center gap-4">
                        <div className="flex items-center">
                            <div className="z-10">
                                <div className="relative w-[48px] h-[48px]"><img
                                    className="size-full rounded-full bg-white"
                                    src="https://assets.smold.app/api/token/1/0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0/logo-128.png"
                                    width="100%"
                                    height="100%"
                                    alt="0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0"/></div>
                            </div>
                            <div className="z-0 -ml-5">
                                <div className="relative w-[48px] h-[48px]">
                                    <img
                                        className="size-full rounded-full bg-white"
                                        src="https://assets.smold.app/api/token/1/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo-128.png"
                                        width="100%"
                                        height="100%"
                                        alt="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"/></div>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <div
                                    className="uppercase text-white font-bold font-syncopate text-xl lg:text-2xl whitespace-nowrap">wstETH / WETH</div>
                            </div>
                        </div>
                        <div
                            className="items-center justify-center py-1 rounded-xl text-white whitespace-nowrap bg_head_gray px-4 text-sm details-hidden">ERC20 Pool</div>
                    </div>
                </div>
            </div>
        )
}

export default DetailsTitle