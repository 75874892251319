import React from 'react'

import LeftGrid from '../sections/borrow/LeftGrid'
import RightGrid from '../sections/borrow/RightGrid'

function Index() {
  return (
    <>
        <div className='class="relative grid min-h-[850px] grid-cols-1 md:grid-cols-12 gap-6 md:gap-x-6 text-white md:mx-auto md:max-w-[1200px]"'>
            <LeftGrid/>
            <RightGrid/>
        </div>
    </>
  )
}

export default Index