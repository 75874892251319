import React from 'react'

function Index() {
    return (
        <div className="absolute left-0 top-0 flex size-full md:mx-auto md:max-w-[1200px]">
            <div
                className="col-span-7 flex w-full flex-col items-center rounded-3xl bg-gray-595 p-6 pb-16">
                <div className="mb-8 flex w-full items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        className="mr-3 w-10 cursor-pointer">
                        <path
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M11.667 6.667 8.334 10l3.333 3.334"></path>
                    </svg>
                    <h1
                        className="font-syncopate text-base font-semibold uppercase leading-normal md:text-xl">Review</h1>
                </div>
                <div className="md:min-w-[428px]">
                    <div
                        className="flex flex-col justify-between gap-4 border-b border-gray-560 pb-5 md:flex-row">
                        <div className="flex flex-col gap-4"></div>
                        <button
                            className="border-2 cursor-pointer flex font-bold gap-3 items-center justify-center rounded-4xl text-center transition-all bg-transparent hover:bg-ajna-ghost border-ajna-ghost text-white px-4 py-1 text-sm size-fit"
                            type="button">
                            <span className="text-center">Change</span>
                        </button>
                    </div>
                    <section className="flex flex-col gap-4 pt-5 text-sm">
                        <h3 className="text-xs font-semibold text-gray-700">Details</h3>
                        <div className="flex flex-col gap-2">
                            <p>Liquidation Price:
                                <span className="font-bold">0.0</span>
                                WETH</p>
                            <p>Lowest Utilized Price:
                                <span className="font-bold">0.0</span>
                                WETH</p>
                        </div>
                    </section>
                    <div className="flex justify-center">
                        <div
                            className="my-10 flex max-w-[260px] items-center gap-2 rounded-lg bg-gray-597 px-6 py-4 text-xs text-gray-300">
                            <svg
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                color="#AEAFC2">
                                <g
                                    clip-path="url(#info_svg__a)"
                                    stroke="#AEAFC2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round">
                                    <path d="M12 21a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z" strokeWidth="1.5"></path>
                                    <path d="M12 8h.01" strokeWidth="2"></path>
                                    <path d="M11 12h1v4h1" strokeWidth="1.5"></path>
                                </g>
                                <defs>
                                    <clipPath id="info_svg__a">
                                        <path fill="#AEAFC2" d="M0 0h24v24H0z"></path>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span>Prices are estimated calculations</span>
                        </div>
                    </div>
                    <button
                        className="border-2 cursor-pointer flex font-bold gap-3 items-center justify-center rounded-4xl text-center transition-all border-transparent text-white bg-ajna-primary hover:bg-ajna-primary-accent px-6 py-2.5 text-md w-full"
                        type="button">
                        <span className="text-center">Confirm &amp; Send</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Index