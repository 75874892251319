import React from 'react';

const ProgressBar = ({ minValue, maxValue, currentValue, onChange }) => {
  // Calculate percentage of current value relative to min and max
  const percentage = ((currentValue - minValue) / (maxValue - minValue)) * 100;

  // Function to handle slider changes
  const handleSliderChange = (e) => {
    const newValue = parseFloat(e.target.value);
    onChange(newValue);
  };

  return (
    <div className="m-auto my-10">
      <div className="mb-5 flex flex-row justify-between items-center text-[14px] text-white">
        <span>{minValue}%</span>
        <span>{maxValue}%</span>
      </div>
      <div className="flex items-center">
        <div className="flex-1 h-2 relative">
          {/* <div className="absolute inset-0 rounded-full bg-gray-300"></div> */}
          {/* <div className="absolute inset-y-0 left-0 rounded-full bg-red-500" style={{ width: `${percentage}%` }}></div> */}
          <input
            type="range"
            min={minValue}
            max={maxValue}
            step="0.5"
            value={currentValue}
            onChange={handleSliderChange}
            className="absolute inset-y-0 left-0 w-full h-6 flex justify-center items-center rounded-full cursor-grab shadow-sm bg-[#9F47D2]"
            tabIndex="0"
            aria-orientation="horizontal"
            aria-valuemin={minValue}
            aria-valuemax={maxValue}
            aria-valuenow={currentValue}
            aria-disabled="true"
          />
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
