import React from 'react'
import { Link } from 'react-router-dom'
import { BsPlusLg } from "react-icons/bs";


function HomeCrumb() {
    return (
        <div
            className="flex w-full items-center gap-4 flex-wrap justify-between whitespace-nowrap px-3 md:px-0">
            <div className="flex font-light gap-1 md:gap-2 items-center justify-center text-sm">
                <Link
                    className="text-center no-underline hover:underline max-w-[80px] md:max-w-[250px] text-[14px] truncate text-primary-200"
                    to="/">
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mt-[-2px]">
                        <path
                            d="M4.16699 17.5V10.8333H1.66699L10.0003 3.33334L18.3337 10.8333H15.8337V17.5H4.16699ZM10.0003 5.575L5.83366 9.325V15.8333H14.167V9.325L10.0003 5.575ZM9.58366 15.8333V12.5H7.50033L10.417 6.66667V10H12.5003L9.58366 15.8333Z"
                            fill="white"></path>
                    </svg>
                </Link>
                <span className="text-gray-300">&gt;</span>
                <a
                    className="text-center no-underline hover:underline hover:text-white max-w-[80px] md:max-w-[250px] text-[14px] truncate text-primary-200"
                    href="/ethereum/markets">Markets</a>
                <span className="text-gray-300">&gt;</span>
                <a
                    className="text-center no-underline hover:underline hover:text-white max-w-[80px] md:max-w-[250px] text-[14px] truncate font-bold text-gray-400"
                    href="/ethereum/markets">Pools</a>
            </div>
            <Link to="/new-pool"
                className=" cursor-pointer flex font-bold gap-3 items-center justify-center rounded-3xl text-center transition-all border-transparent text-white button_bg hover:text-white px-6 py-1.5 text-base"
                type="button">
                <span className="w-[18px] h-auto">
                    <BsPlusLg className='text-white text-lg' />
                </span>
                <span className="text-center">New Pool</span>
            </Link>
        </div>
    )
}

export default HomeCrumb