import React from 'react'
import { Link } from 'react-router-dom'

function LeftGrid() {
    return (
        <div className="col-span-7 flex flex-col rounded-3xl bg-gray-595 p-6 pb-16 !text-white">
            <div className="mb-8 flex items-center">
                <Link to="/">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        className="mr-3 w-10">
                        <path
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M11.667 6.667 8.334 10l3.333 3.334"></path>
                    </svg>
                </Link>
                <h1
                    className="font-syncopate text-base font-semibold uppercase leading-normal md:text-xl text-white m-0">Borrow</h1>
            </div>
            <div className="flex flex-col">
                <h2 className="mb-4 font-syncopate text-sm font-semibold uppercase text-white">Overview</h2>
                <div className="grid grid-cols-2 gap-x-4 gap-y-12">
                    <div className="flex flex-col gap-2 justify-between">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Status</span>
                        </div>
                        <div
                            className="inline-flex items-center justify-center px-3 py-1 rounded-xl text-[14px] w-fit bg-purple-300 text-black">Can not be liquidated</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Borrower Interest Rate</span>
                        </div>
                        <div
                            className="bg-gray-590 inline-flex items-center justify-center px-3 py-1 rounded-xl text-white text-[14px] w-fit">4.413 %</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="0.0">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Liquidation Price</span>
                            <div className="cursor-pointer " data-state="closed">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                    className="size-5">
                                    <g
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        clip-path="url(#icon-question_svg__a)"
                                        opacity="0.8">
                                        <path d="M10 17.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15"></path>
                                        <path
                                            d="M10 11.042v-.209c0-.68.42-1.05.842-1.333.412-.277.824-.64.824-1.306a1.667 1.667 0 0 0-3.333 0M9.999 13.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="icon-question_svg__a">
                                            <path fill="#fff" d="M0 0h20v20H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="text-base leading-7 md:text-2xl md:leading-9">
                            <b>0.0</b>
                            WETH</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="0.0">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Threshold Price</span>
                            <div className="cursor-pointer " data-state="closed">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                    className="size-5">
                                    <g
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        clip-path="url(#icon-question_svg__a)"
                                        opacity="0.8">
                                        <path d="M10 17.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15"></path>
                                        <path
                                            d="M10 11.042v-.209c0-.68.42-1.05.842-1.333.412-.277.824-.64.824-1.306a1.667 1.667 0 0 0-3.333 0M9.999 13.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="icon-question_svg__a">
                                            <path fill="#fff" d="M0 0h20v20H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="text-base leading-7 md:text-2xl md:leading-9">
                            <b>0.0</b>
                            WETH</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="1.144151850726659798">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Lowest Utilization Price</span>
                            <div className="cursor-pointer " data-state="closed">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                    className="size-5">
                                    <g
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        clip-path="url(#icon-question_svg__a)"
                                        opacity="0.8">
                                        <path d="M10 17.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15"></path>
                                        <path
                                            d="M10 11.042v-.209c0-.68.42-1.05.842-1.333.412-.277.824-.64.824-1.306a1.667 1.667 0 0 0-3.333 0M9.999 13.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="icon-question_svg__a">
                                            <path fill="#fff" d="M0 0h20v20H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="text-base leading-7 md:text-2xl md:leading-9">
                            <b>1.144151</b>
                            WETH</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="0.0">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Collateral</span>
                            <div className="cursor-pointer " data-state="closed">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                    className="size-5">
                                    <g
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        clip-path="url(#icon-question_svg__a)"
                                        opacity="0.8">
                                        <path d="M10 17.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15"></path>
                                        <path
                                            d="M10 11.042v-.209c0-.68.42-1.05.842-1.333.412-.277.824-.64.824-1.306a1.667 1.667 0 0 0-3.333 0M9.999 13.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="icon-question_svg__a">
                                            <path fill="#fff" d="M0 0h20v20H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="text-base leading-7 md:text-2xl md:leading-9">
                            <b>0.0</b>
                            wstETH</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="0.0">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Debt</span>
                        </div>
                        <div className="text-base leading-7 md:text-2xl md:leading-9">
                            <b>0.0</b>
                            WETH</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="246.416474316457188978">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Available to Borrow in Pool</span>
                            <div className="cursor-pointer " data-state="closed">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                    className="size-5">
                                    <g
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        clip-path="url(#icon-question_svg__a)"
                                        opacity="0.8">
                                        <path d="M10 17.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15"></path>
                                        <path
                                            d="M10 11.042v-.209c0-.68.42-1.05.842-1.333.412-.277.824-.64.824-1.306a1.667 1.667 0 0 0-3.333 0M9.999 13.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="icon-question_svg__a">
                                            <path fill="#fff" d="M0 0h20v20H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="text-base leading-7 md:text-2xl md:leading-9">
                            <b>246.416474</b>
                            WETH</div>
                    </div>
                    <div className="flex flex-col gap-2 justify-between" title="0.0">
                        <div className="flex gap-2">
                            <span className="text-sm text-gray-100">Minimum loan amount</span>
                            <div className="cursor-pointer " data-state="closed">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                    className="size-5">
                                    <g
                                        stroke="#fff"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="1.5"
                                        clip-path="url(#icon-question_svg__a)"
                                        opacity="0.8">
                                        <path d="M10 17.5a7.5 7.5 0 1 1 0-15 7.5 7.5 0 0 1 0 15"></path>
                                        <path
                                            d="M10 11.042v-.209c0-.68.42-1.05.842-1.333.412-.277.824-.64.824-1.306a1.667 1.667 0 0 0-3.333 0M9.999 13.333a.208.208 0 1 0 .002.417.208.208 0 0 0-.002-.417"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="icon-question_svg__a">
                                            <path fill="#fff" d="M0 0h20v20H0z"></path>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </div>
                        </div>
                        <div className="text-2xl">
                            <b>0.0</b>
                            WETH</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LeftGrid