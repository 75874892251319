import React from 'react'
import GridItem from '../components/pool/GridItem'

function GridView() {
  return (

    <div className='grid grid-cols-1 items-center justify-center gap-x-4 gap-y-8 md:grid-cols-2 lg:grid-cols-3 mt-4'>
        <GridItem/>
        <GridItem/>
        <GridItem/>
    </div>
  )
}

export default GridView