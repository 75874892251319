import React from 'react'

function WalletImage() {
    return (
        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 80 80"
                            width="60"
                            height="60">
                            <g clipPath="url(#icon-pool_svg__a)">
                                <mask
                                    id="icon-pool_svg__b"
                                    width="80"
                                    height="80"
                                    x="0"
                                    y="0"
                                    maskUnits="userSpaceOnUse"
                                    style={{maskType: 'luminance'}}>
                                    <path fill="#fff" d="M80 0H0v80h80z"></path>
                                </mask>
                                <g mask="url(#icon-pool_svg__b)">
                                    <path
                                        fill="url(#icon-pool_svg__c)"
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="M44.516 6 3.333 30.13l44.4 26.061s2.574 1.609 4.504 1.609 4.505-1.609 4.505-1.609L77.333 44.61l-28.956-16.73 17.052-9.975z"></path>
                                    <path
                                        fill="url(#icon-pool_svg__d)"
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="m65.428 17.905-17.052 9.974 14.8 8.365 1.609-.965z"></path>
                                    <path
                                        fill="url(#icon-pool_svg__e)"
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="M3.333 45.252V30.13s44.4 26.278 46.33 27.135c1.93.856 2.574.636 2.574.636v15.664z"></path>
                                    <path
                                        fill="url(#icon-pool_svg__f)"
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="M52.238 57.9v15.665l24.774-14.157.322-14.8S55.339 57.01 54.169 57.478s-1.93.422-1.93.422Z"></path>
                                    <path
                                        fill="url(#icon-pool_svg__g)"
                                        d="m10.09 29.808 34.748-19.626 13.513 8.044-6.757 4.189-9.33 5.785 27.991 16.087-6.756 3.86-7.079 4.183s-1.287.644-4.182.644-4.505-.966-4.505-.966L17.206 33.991z"></path>
                                    <path
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="M44.838 10.182 10.09 29.808l7.116 4.183m27.632-23.809 13.513 8.044-6.757 4.189m-6.756-12.233v8.044M17.206 33.99l27.632-15.765M17.206 33.99l30.527 18.017s1.609.966 4.505.966 4.182-.644 4.182-.644l7.078-4.182M51.595 22.415l-9.33 5.785m9.33-5.785-6.756-4.19M42.264 28.2l27.991 16.087-6.756 3.86M42.264 28.2v7.4l21.234 12.548"></path>
                                    <path
                                        fill="url(#icon-pool_svg__h)"
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="m44.838 18.227-27.67 15.765L48.055 52.33s1.609.965 4.182.965 4.183-.965 4.183-.965l7.078-4.183L42.264 35.6v-7.4l9.33-5.79z"></path>
                                    <path
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="M22.316 36.887c1.502-.536 4.89-2.059 9.01-5.148 5.147-3.86 4.825-3.86 9.651-8.044 1.073-.858 4.826-3.217 6.757-3.539M28.75 40.748c1.716-.858 4.826-2.573 5.47-4.826 2.252-3.86 5.791-6.113 8.043-5.791M33.9 43.965c1.394-.965 3.217-1.286 5.47-4.182.25-.322 1.93-3.54 2.895-4.183m-1.93 11.904c1.501-.965 3.86-2.573 4.504-3.86.432-.864 1.93-3.86 4.183-4.183m-2.574 11.583c1.501-.965 3.64-3.032 4.504-4.183.965-1.287 3.218-2.574 5.148-3.217"></path>
                                    <path
                                        stroke="#1A2054"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M51.272 9.538c-1.072-1.501-4.183.252-4.504 4.114m0 0 5.47 3.217m-5.47-3.217v3.217m5.47 0c.32-3.862 2.573-5.47 3.86-4.505m-3.86 4.505v3.217m-5.47-3.217v2.25m0-2.25 5.47 3.217m0 0v2.001"></path>
                                    <path
                                        stroke="#1A2054"
                                        strokeLinejoin="round"
                                        d="M23.924 30.13c2.574.644 7.142 2.382 12.548 8.044s9.33 6.987 12.225 7.4c2.253.322 6.178 1.416 9.01 5.791M42.262 33.168c-1.072-1.072-4.826-3.953-8.365-5.47-.644-.275-2.253-1.105-2.253-2.07m6.114-3.54c.321.43 1.287.965 2.252 1.287s3.217 1.93 4.182 3.54"></path>
                                    <path fill="url(#icon-pool_svg__i)" d="M12 20a8 8 0 1 0 0-16 8 8 0 0 0 0 16"></path>
                                    <path
                                        fill="#fff"
                                        d="M32.759 73.817a.667.667 0 1 0-.967.918.667.667 0 0 0 .967-.918M24.667 8.667a.667.667 0 1 0 0-1.334.667.667 0 0 0 0 1.334M62.667 6a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333M72.333 72a1 1 0 1 0 0-2 1 1 0 0 0 0 2M71.333 7.333a1.333 1.333 0 1 0 0-2.666 1.333 1.333 0 0 0 0 2.666M24.852 70.3a1.333 1.333 0 1 0-1.934 1.836 1.333 1.333 0 0 0 1.934-1.836M11.564 59.275c-1.862-.312-2.236-3.036-2.236-3.066a.177.177 0 0 0-.18-.153.18.18 0 0 0-.172.162c0 .028-.248 2.767-2.094 3.164a.174.174 0 0 0-.137.172.17.17 0 0 0 .145.165c1.862.312 2.235 3.037 2.236 3.066a.177.177 0 0 0 .18.153.18.18 0 0 0 .172-.161c0-.03.248-2.768 2.094-3.165a.174.174 0 0 0 .137-.172.17.17 0 0 0-.145-.165M76.092 22.667c-2.638-.744-2.748-4.734-2.745-4.776a.257.257 0 0 0-.235-.249.26.26 0 0 0-.274.205c-.004.042-.793 3.955-3.52 4.238a.25.25 0 0 0-.224.226.246.246 0 0 0 .182.261c2.638.744 2.748 4.734 2.745 4.776.006.128.1.238.235.25a.264.264 0 0 0 .274-.206c.004-.042.794-3.955 3.52-4.237a.25.25 0 0 0 .224-.227.246.246 0 0 0-.182-.26"></path>
                                </g>
                            </g>
                            <defs>
                                <linearGradient
                                    id="icon-pool_svg__c"
                                    x1="40.333"
                                    x2="40.333"
                                    y1="6"
                                    y2="57.8"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#D6A1FF"></stop>
                                    <stop offset="0.55" stopColor="#9146D0"></stop>
                                    <stop offset="1" stopColor="#4714C9"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="icon-pool_svg__d"
                                    x1="56.902"
                                    x2="56.902"
                                    y1="17.905"
                                    y2="36.244"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#7C56DD"></stop>
                                    <stop offset="1" stopColor="#3D1999"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="icon-pool_svg__e"
                                    x1="27.785"
                                    x2="27.785"
                                    y1="30.13"
                                    y2="73.565"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#D19BFB"></stop>
                                    <stop offset="0.477" stopColor="#A555D2"></stop>
                                    <stop offset="1" stopColor="#3400C9"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="icon-pool_svg__f"
                                    x1="64.786"
                                    x2="64.786"
                                    y1="44.608"
                                    y2="73.565"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#7C56DD"></stop>
                                    <stop offset="1" stopColor="#3700C3"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="icon-pool_svg__g"
                                    x1="40.172"
                                    x2="40.172"
                                    y1="10.182"
                                    y2="52.974"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#51F2FA"></stop>
                                    <stop offset="1" stopColor="#43FFDD"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="icon-pool_svg__h"
                                    x1="40.333"
                                    x2="40.333"
                                    y1="18.227"
                                    y2="53.296"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#5CC8EF"></stop>
                                    <stop offset="1" stopColor="#0B8AD1"></stop>
                                </linearGradient>
                                <linearGradient
                                    id="icon-pool_svg__i"
                                    x1="12"
                                    x2="12"
                                    y1="4"
                                    y2="20"
                                    gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#D7A0FF"></stop>
                                    <stop offset="1" stopColor="#FFCFA2"></stop>
                                </linearGradient>
                                <clipPath id="icon-pool_svg__a">
                                    <path fill="#fff" d="M0 0h80v80H0z"></path>
                                </clipPath>
                            </defs>
                        </svg>
    )
}

export default WalletImage