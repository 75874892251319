import React, {useState, useEffect} from 'react'

import ButtonGlow from '../../UI_Elements/Button/ButtonGlow'

import {navItems} from "./NavItems.js";

import * as Icons from "react-icons/fa";
import * as IconSpiral from 'react-icons/gi';

import "./Navbar.css";
import { images } from '../../assets/index.js';
import BanditCampaign from '../../pages/components/bandit/Index.js';
import { Link } from 'react-router-dom';


function Landing() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleBanditCampaign = () => setIsOpen(!isOpen);
    const toggleBanditClose = () => setIsOpen(false);
    
    const [mobile,
        setMobile] = useState(false);
    const [sidebar,
        setSidebar] = useState(false);

    useEffect(() => {
        if (window.innerWidth < 800) {
            setMobile(true);
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 800) {
                setMobile(true);
            } else {
                setMobile(false);
                setSidebar(false);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
        <BanditCampaign
        isOpen={isOpen}
        onClose={toggleBanditClose}/>
        <div className='flex justify-between items-center md:mx-auto md:max-w-[1200px]'>
            
            <Link to='/' className="text-white flex items-center gap-4 text-[22px] sm:text-[28px]">
                <img
                    src={images.logo[0]}
                    width={30}
                    height={38}
                    alt="deficonnect"
                    className="mx-auto"
                />
            </Link>

            {!mobile && (
                    <nav className='flex my-auto'>
                        <div className='m-2 flex w-full gap-2 font-bold sm:gap-6 lg:gap-10 xl:gap-16'>
                            {navItems.map((item, index) => {
                                return(
                                    <a key={index} href={item.path} className={`text-base text-center no-underline hover:underline !text-white `}>{item.title}</a>
                                );
                                })
                            }
                        </div> 
                        
                    </nav>
                )}
                
            <div className='flex justify-between items-center '>
                

                {!mobile && (
                    <div className=' justify-between ml-4'>
                        <div
                        onClick={toggleBanditCampaign}
                        className="p-0.5 rounded-3xl bg-gradient-to-r from-[#9F47D2] to-[#AA450C] mx-8 w-full">
                            <button
                                className="border-2 cursor-pointer flex font-bold gap-3 items-center justify-center rounded-3xl text-center transition-all bg-black hover:bg-gradient-to-r hover:from-[#B5189F] hover:to-[#3C0080] border-transparent text-white  py-2.5 text-md w-full bg-origin-border"
                                type="button">
                                <span className="text-center">Connect Wallet</span>
                            </button>
                        </div>
                    </div>
                )}
            </div>

        {mobile && (
          <div className="sidebar-toggle">
            {sidebar ? (
              <Icons.FaTimes
                className="sidebar-toggle-logo"
                onClick={() => setSidebar(!sidebar)}
              />
            ) : (
              <IconSpiral.GiDividedSpiral
                className="sidebar-toggle-logo "
                onClick={() => setSidebar(!sidebar)}
              />
            )}
          </div>
        )}
        </div>
        
        <div className={sidebar ? "sidebar shadow-md z-50 active" : "sidebar "} >
        <div className='span__pseudo' onClick={() => setSidebar(!sidebar)}></div>
        <div className="text-black flex items-center gap-4 p-4">
            <div className="w-[30px] pl-2">
            <Link to='/' >
                <img
                    src={images.logo[0]}
                    width={30}
                    height={38}
                    alt="deficonnect"
                    className="mx-auto"
                />
            </Link>
            
            </div>
            <div className="relative"><span className="text-lg">DefiConnect</span></div>
        </div>
        
        <ul className="sidebar-items">
        {navItems.map((item) => {
                        return (
                            <li key={item.id} className={item.sName}>
                                <a href={item.path} 
                                className={`text-sm text-black`}
                                onClick={() => setSidebar(!sidebar)}>{item.title}</a>
                            </li>
                        );
                    })
}
        </ul>
        <div className=' flex-col hidden items-center mt-24'>
            <ButtonGlow to='/'>Connect</ButtonGlow>
        </div>
      </div>
        </>
    )
}

export default Landing
