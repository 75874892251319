import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik';
import { LiaPlusCircleSolid } from "react-icons/lia";


import PoolImage from '../../assets/PoolImage'
import SwitchSlider from '../components/switch/Index';
import TokenSelectInput from '../components/tokenSelectInput/Index'
import { Link } from 'react-router-dom';
import ProgressBar from '../components/progressBar/Index';


function NewPool() {
    const [isChecked, setIsChecked] = useState(false);

    const handleChange = () => {
        setIsChecked(!isChecked);
    };
    
    const tokens = [
        { name: 'Token A', icon: '🔵' },
        { name: 'Token B', icon: '🟢' },
        { name: 'Token C', icon: '🟡' }
    ];

    const [progressValue, setProgressValue] = useState(5);
    const minValue = 0;
    const maxValue = 10; 

    const handleProgressChange = (newValue) => {
        setProgressValue(newValue);
    };
    
    return (<> 
    <Formik
      initialValues={{ collateralToken: '', quoteToken: '' }}
      onSubmit={(values) => {
        console.log(values);
      }}
    >
    <Form>
    <div
        className="text-gray-13 rounded-3xl bg-[#121217A6] px-8 py-6 md:mx-auto md:max-w-[960px]">
        <header className="mb-10 flex flex-col justify-between gap-6 md:flex-row">
            <div className="flex items-center">
                <Link to="/">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 20 20"
                        className="mr-3 w-10">
                        <path
                            stroke="#fff"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="1.5"
                            d="M11.667 6.667 8.334 10l3.333 3.334"></path>
                    </svg>
                </Link>
                <h1 className="font-syncopate text-sm font-semibold uppercase md:text-base mb-0 text-white">New Pool</h1>
                <div
                    className="bg-gray-590 inline-flex items-center justify-center px-3 py-1 rounded-xl text-white text-[14px] ml-4 uppercase">ERC-20</div>
            </div>
            <div className="flex items-center gap-6">
                <div className="flex justify-center m-auto ml-0">
                    <p className="my-auto mr-4 whitespace-nowrap text-[14px] text-white">NFT as Collateral</p>
                    <SwitchSlider/>
                    </div>
                </div>
            </header>
            <main className="mx-auto md:max-w-screen-xs">
                <div className="mb-16 block undefined">
                    <h3 className="mb-5 font-semibold text-gray-700">Token pair</h3>
                    <div className="flex flex-col items-center justify-between md:flex-row">
                    <Field
                        name="collateralToken"
                        component={TokenSelectInput}
                        label="Collateral token"
                        placeholder="Select token"
                        tokens={tokens}
                    />
                    <div className="z-10 mx-4 mb-[-16px] mt-[-9px] w-full md:my-0">
                        <LiaPlusCircleSolid className='bg-[#121217] text-3xl mx-auto rounded-full text-white'/>
                    </div>
                    <Field
                        name="quoteToken"
                        component={TokenSelectInput}
                        label="Quote token"
                        placeholder="Select token"
                        tokens={tokens}
                    />
                    </div>
                </div>
                <div
                    className="flex w-full items-center rounded-2xl border border-gray-700 p-5 undefined">
                    <div className="flex-1">
                        <h5 className="font-rubik mb-2 text-left text-lg font-semibold text-white">Initial Interest Rate</h5>
                        <p className="font-rubik text-left text-xs font-normal text-gray-700">Enter an interest rate between 1% - 10%</p>
                        <h5 className="font-rubik mb-2 text-left text-lg font-semibold text-white">{progressValue}%</h5>
                    </div>
                </div>
                <ProgressBar
                    minValue={minValue}
                    maxValue={maxValue}
                    currentValue={progressValue}
                    onChange={handleProgressChange}
                />
                <button
                    className="border-2 text-white flex font-bold gap-3 items-center justify-center rounded-3xl text-center transition-all bg-gradient-to-r from-[#9F47D2] to-[#AA450C] border-transparent px-6 py-3.5 text-md w-full"
                    disabled=""
                    type="submit">
                    <span className="text-center">Create Pool</span>
                </button>
            </main>
            <div className="mt-8 font-semibold text-gray-700">Matching Pools (0)</div>
            <div
                className="my-5 flex h-[240px] max-w-[960px] flex-col flex-wrap items-center justify-center gap-4 rounded-3xl border border-gray-580 bg-gray-597 px-4 text-center md:mx-auto ">
                <div className="flex flex-col items-center justify-center gap-2.5">
                    <div
                        className="flex size-[80px] items-center justify-center rounded-[50%] bg-gray-590">
                        <PoolImage/>
                    </div>
                    <p className="text-center text-sm leading-5 text-gray-300 ">Enter token pair to see if a matching pool already exists</p>
                </div>
            </div>
        </div>
        </Form>
        </Formik>
    </>)
}

export default NewPool