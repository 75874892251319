import React from 'react'

function LeftGrid() {
    return (
        <div
            className="w-auto rounded-3xl border border-solid border-gray-580 bg-[1C1C1C4D] p-5 lg:w-[360px] lg:min-w-[360px] !text-white">
            <div
                className="px-5 font-syncopate text-sm font-bold uppercase text-gray-900 lg:pt-4">Total Tokens Locked</div>
            <div className="mt-3 flex flex-col gap-2 px-5">
                <div className="flex items-center justify-between text-sm text-white">
                    <div className="flex items-center gap-2">
                        <div className="relative w-[48px] h-[48px] size-5">
                            <img
                                className="size-full rounded-full bg-white"
                                src="https://assets.smold.app/api/token/1/0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0/logo-128.png"
                                width="100%"
                                height="100%"
                                alt="0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0"/></div>
                            <b>wstETH</b>
                        </div>
                        <div className="text-base">17.742512</div>
                    </div>
                    <div className="flex items-center justify-between text-sm text-white">
                        <div className="flex items-center gap-2">
                            <div className="relative w-[48px] h-[48px] size-5">
                                <img
                                    className="size-full rounded-full bg-white"
                                    src="https://assets.smold.app/api/token/1/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo-128.png"
                                    width="100%"
                                    height="100%"
                                    alt="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"/></div>
                                <b>WETH</b>
                            </div>
                            <div className="text-base">246.647950</div>
                        </div>
                    </div>
                    <div className="mt-7 px-5">
                        <div className="font-syncopate text-sm font-bold uppercase text-gray-900">Current Utilization</div>
                        <div className="mt-3 flex w-full">
                            <div className="box-border w-[100px]">
                                <svg viewBox="0 0 100 100">
                                    <g transform="translate(0, 0)">
                                        <g>
                                            <path
                                                d="
          M50,50
          L100,50
          A50,50
          0 1,1
          97.87098161968737,35.56500367968337
          z
        "
                                                fill="#627EEA"
                                                stroke="#fff"
                                                strokeWidth="0"
                                                strokeLinejoin="round"
                                                style={{"transitionProperty": "all", "transitionTimingFunction": "easeOut", transitionDuration: "0s"}}>
                                                <title>Deposit</title>
                                            </path>
                                            <path
                                                d="
          M50,50
          L97.87098161968737,35.56500367968337
          A50,50
          0 0,1
          100,49.999999999999986
          z
        "
                                                fill="#F4B731"
                                                stroke="#fff"
                                                strokeWidth="0"
                                                strokeLinejoin="round"
                                                style={{"transitionProperty": "all", "transitionTimingFunction": "easeOut", transitionDuration: "0s"}}>
                                                <title>Debt</title>
                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="ml-1 flex w-full flex-col justify-around gap-2 text-sm">
                                <div className="flex items-center">
                                    <div className="m-2 flex size-[6px] rounded-full bg-[#627EEA]"></div>
                                    <div className="flex flex-col">
                                        <p>Deposit 246.39
                                            <b>WETH</b>
                                        </p>
                                        <span className="text-xs">95.33%</span>
                                    </div>
                                </div>
                                <div className="flex items-center">
                                    <div className="m-2 flex size-[6px] rounded-full bg-[#F4B731]"></div>
                                    <div className="flex flex-col">
                                        <p>Debt 12.04
                                            <b>WETH</b>
                                        </p>
                                        <span className="text-xs">4.66%</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-6 flex flex-wrap justify-around gap-2 lg:justify-between">
                        <div
                            className="box-border hidden h-[128px] flex-col justify-start gap-2 rounded-2xl bg-gray-597 pl-5 pr-3 pt-3 lg:flex lg:w-[154px]">
                            <div className="text-xl lg:text-2xl">4.49%</div>
                            <div className="text-sm font-semibold text-gray-700">Meaningful Actual Utilization</div>
                        </div>
                        <div
                            className="box-border flex h-[104px] w-[148px] flex-col justify-start gap-2 rounded-lg bg-gray-597 pl-6 pt-7 sm:h-[128px] lg:w-[154px] lg:rounded-2xl lg:pt-3">
                            <div className="text-xl lg:text-2xl">74.92%</div>
                            <div className="text-xs font-semibold text-gray-700 lg:text-sm">Target Utilization</div>
                        </div>
                        <div
                            className="box-border flex h-[104px] w-[148px] flex-col justify-start gap-1 overflow-hidden rounded-lg bg-gray-597 pl-6 pt-7 sm:h-[128px] lg:mt-[10px] lg:basis-full lg:rounded-2xl">
                            <div className="text-xs font-semibold text-gray-700 lg:text-base">AJNA Burned</div>
                            <div className="text-xl text-white lg:text-2xl">🔥 113,214.407928</div>
                        </div>
                    </div>
                    <div className="mt-6 flex items-center gap-1">
                        <span className="w-[55%] text-xs text-gray-100 lg:text-sm">Quote Token (WETH)</span>
                        <span className="text-sm font-bold text-white lg:text-base">0xc02a...6cc2</span>
                        <div className="cursor-pointer " data-state="closed">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-2 inline-block cursor-pointer">
                                <path
                                    d="M7 17H5C4.46957 17 3.96086 16.7893 3.58579 16.4142C3.21071 16.0391 3 15.5304 3 15V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V7"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path
                                    d="M19.895 7H8.105C7.49472 7 7 7.49472 7 8.105V19.895C7 20.5053 7.49472 21 8.105 21H19.895C20.5053 21 21 20.5053 21 19.895V8.105C21 7.49472 20.5053 7 19.895 7Z"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="mt-3 flex items-center justify-start gap-1">
                        <span className="w-[55%] text-xs text-gray-100 lg:text-sm">Collateral Token (wstETH)</span>
                        <span className="text-sm font-bold text-white lg:text-base">0x7f39...2ca0</span>
                        <div className="cursor-pointer " data-state="closed">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="ml-2 inline-block cursor-pointer">
                                <path
                                    d="M7 17H5C4.46957 17 3.96086 16.7893 3.58579 16.4142C3.21071 16.0391 3 15.5304 3 15V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H15C15.5304 3 16.0391 3.21071 16.4142 3.58579C16.7893 3.96086 17 4.46957 17 5V7"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                                <path
                                    d="M19.895 7H8.105C7.49472 7 7 7.49472 7 8.105V19.895C7 20.5053 7.49472 21 8.105 21H19.895C20.5053 21 21 20.5053 21 19.895V8.105C21 7.49472 20.5053 7 19.895 7Z"
                                    stroke="white"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"></path>
                            </svg>
                        </div>
                    </div>
                    <div className="mt-3 flex items-center gap-1 text-xs text-ajna-ghost lg:text-sm">
                        <a
                            href="https://etherscan.io/address/0x3ba6a019ed5541b5f5555d8593080042cf3ae5f4"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="text-ajna-ghost text-base underline">View on Block Explorer</a>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none">
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="m8 8 6-6v4.667V2H9.333"></path>
                            <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M6 2H3.333C2.597 2 2 2.597 2 3.333v9.334C2 13.403 2.597 14 3.333 14h9.334c.736 0 1.333-.597 1.333-1.333V10"></path>
                        </svg>
                    </div>
                </div>

            )
}

export default LeftGrid