import React, { useState } from 'react';
import './switch.css';

const SwitchSlider = () => {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <label className="switch">
      <input
        aria-label="Checkbox"
        type="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <span className="slider"></span>
    </label>
  );
};

export default SwitchSlider;
