import React, { useState } from 'react';
import { Field } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import Modal from './Modal'; // Import your modal component
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';

const TokenSelectInput = ({ field, form, label, placeholder, tokens }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSelectToken = (token) => {
    form.setFieldValue(field.name, token);
    setIsModalOpen(false);
  };

  return (
    <>
      <div
        className="min-w-50 flex w-full grow cursor-pointer items-center justify-between rounded-2xl border border-gray-560 p-3 md:min-w-48"
        onClick={() => setIsModalOpen(true)}
      >
        <div className="relative flex w-full grow items-center justify-between rounded-3xl p-1.5">
          <span className="ml-2 flex items-center whitespace-nowrap text-lg leading-7 text-white">
            {field.value ? (
              <>
                {field.value.icon} {field.value.name}
              </>
            ) : (
              placeholder
            )}
            <span className="ml-2">
              {isModalOpen ? (
                <IoMdArrowDropup className="text-white text-xl" />
              ) : (
                <IoMdArrowDropdown className="text-white text-xl" />
              )}
            </span>
          </span>
          <span className="rounded-md bg-gray-580 px-2 py-1 text-xs font-normal leading-4 text-white md:absolute md:left-[-12px] md:top-16 ">
            {label}
          </span>
        </div>
      </div>

      <AnimatePresence>
        {isModalOpen && (
          <Modal onClose={() => setIsModalOpen(false)}>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="modal-content "
            >
              <h2 className="text-lg font-bold">Select a token</h2>
              <input
                type="text"
                placeholder="Search tokens"
                className="w-full p-2 mt-4 mb-6 border rounded"
              />
              <ul>
                {tokens.map((token) => (
                  <li
                    key={token.name}
                    className="flex items-center p-2 cursor-pointer hover:bg-gray-200"
                    onClick={() => handleSelectToken(token)}
                  >
                    <span className="mr-2">{token.icon}</span>
                    {token.name}
                  </li>
                ))}
              </ul>
            </motion.div>
          </Modal>
        )}
      </AnimatePresence>
    </>
  );
};

export default TokenSelectInput;
