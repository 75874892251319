import React from 'react'
import WalletImage from '../../assets/WalletImage'

function WalletDisplay() {
    return (
        <div className="my-10 flex min-h-[240px] w-full items-center justify-center">
            <div
                className="flex flex-col flex-wrap items-center justify-center gap-4 rounded-3xl border border-gray-580  text-center mx-auto h-[240px] w-full max-w-[960px] bg-burner-100">
                <div className="flex flex-col items-center justify-center gap-4">
                    <div
                        className="flex size-[80px] items-center justify-center rounded-[50%] bg-gray-590">
                        <WalletImage/>
                    </div>
                    <p className="text-center text-[14px] leading-[20px] text-gray-300 ">Your active liquidity pools will appear here</p>
                </div>
                <div className="mt-2 flex w-full justify-center md:w-auto">
                    <div
                        className="p-0.5 rounded-3xl bg-gradient-to-r from-[#9F47D2] to-[#AA450C] mx-8 w-full">
                        <button
                            className="border-2 cursor-pointer flex font-bold gap-3 items-center justify-center rounded-3xl text-center transition-all bg-black hover:bg-gradient-to-r hover:from-[#B5189F] hover:to-[#3C0080] border-transparent text-white px-6 py-2.5 text-md w-full bg-origin-border"
                            type="button">
                            <span className="text-center">Connect Wallet</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WalletDisplay