import React, {useEffect} from 'react';

import {BrowserRouter as Router, Route, Routes, Navigate} from 'react-router-dom';

import AOS from "aos";

import './App.css'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Swap from './pages/Swap'

import { DataContext } from './context/data-context';
import { useData } from './hooks/data-hook';


import 'antd/dist/antd.min.css';
import 'aos/dist/aos.css';
import Header from './default/Header';
import Flyer from './pages/sections/Flyer';
import Footer from './pages/sections/Footer';
import PoolDetails from './pages/poolDetails/Index'
import PoolBorrow from './pages/poolDetails/borrow/Index'
import PoolView from './pages/poolDetails/review/Index'
import PoolLend from './pages/poolDetails/lend/Index'
import NewPool from './pages/sections/NewPool';

function App() {

    useEffect(() => {
        AOS.init({duration: 2000});
        AOS.refresh();
        
    }, []);

    const {address, updateData} = useData()

    let routes;

    routes = (
      <Routes>
        <Route path="/new-pool" element={<NewPool/>} />
        <Route path="/lend" element={<PoolLend/>} />
        <Route path="/review" element={<PoolView/>} />
        <Route path="/borrow" element={<PoolBorrow/>} />
        <Route path="/details/:id" element={<PoolDetails />} />
        <Route path="/" element={<Swap />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
  );


    return (
        <DataContext.Provider
            value={{
            wallet: address,
            logout: updateData
        }}>
          <Router>
          <div className='app__background '>
            <div className='mx-auto flex min-h-screen w-full max-w-[1440px] flex-col justify-between'>
              <main>
                <Header/> 
                <Flyer/>
                <div className="overflow-hidden p-4">
                  <div className='md:mx-auto md:max-w-[1200px]'>
                    {routes}
                  </div>
                </div>
                <Footer/>
              </main>
            </div>
          </div>
          </Router>
        </DataContext.Provider>
    );
}

  export default App;