import React from 'react'

import Landing from './landing/Landing'



function Header() {
    return (
        <nav className='py-2 px-8 md:px-16'>
            <Landing/>
        </nav>
    )
}

export default Header
