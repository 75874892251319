import React from 'react'

function Index() {
    return (
        <div
            className="relative min-h-[700px] flex-col rounded-3xl bg-gray-595 p-5 sm:p-8 md:mx-auto md:max-w-[960px]">
            <div className="mb-6 flex flex-col sm:flex-row ">
                <div className="space-betweeen flex flex-1 items-center gap-3">
                    <a href="/details/0x3ba6a019ed5541b5f5555d8593080042cf3ae5f4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                            className="w-10">
                            <path
                                stroke="#fff"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="1.5"
                                d="M11.667 6.667 8.334 10l3.333 3.334"></path>
                        </svg>
                    </a>
                    <h1
                        className="flex items-center mb-0 font-syncopate text-white text-base font-semibold uppercase md:text-xl">Lend</h1>
                </div>
                <div
                    className="flex justify-around px-2 py-1 rounded-lg bg-gray-597 border border-gray-560 mt-2 sm:mt-0">
                    <div
                        className="flex w-full min-w-fit justify-center rounded-lg px-4 py-1 text-sm font-bold leading-6 text-gray-900 bg-black">Lending</div>
                    <div
                        className="flex w-full min-w-fit justify-center rounded-lg px-4 py-1 text-sm font-bold leading-6 text-gray-500 cursor-pointer">Claimable Collateral</div>
                </div>
            </div>
            <div>
                <div className="flex flex-col-reverse sm:flex-row">
                    <div className="lg:mx-23.5 mx-0 flex-1">
                        <div className="mb-6 hidden flex-col gap-2 sm:flex sm:flex-row">
                            <div
                                className="bg-gray-590 inline-flex items-center justify-center px-3 py-1 rounded-xl text-white whitespace-nowrap text-xs font-normal md:text-sm md:font-semibold">0.172% APR</div>
                            <div
                                className="bg-gray-590 inline-flex items-center justify-center py-1 rounded-xl text-white text-[14px] whitespace-nowrap px-1 sm:px-2">
                                <div className="relative w-[24px] h-[24px] mr-3 size-5">
                                    <img
                                        className="size-full rounded-full bg-white"
                                        src="https://assets.smold.app/api/token/1/0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0/logo-128.png"
                                        width="100%"
                                        height="100%"
                                        alt="0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0"/></div>
                                    <span className="text-xs sm:text-sm">1&nbsp;wstETH = 1.144151&nbsp;WETH</span>
                                </div>
                            </div>
                            <div className="flex flex-col flex-1">
                                <div
                                    className="flex flex-col rounded-3xl border border-solid border-gray-580 bg-burner-100">
                                    <div className="flex h-[64px] items-center px-4 py-3">
                                        <span className="text-xs font-semibold leading-5 text-gray-700">Pool Order Book</span>
                                    </div>
                                    <div
                                        className="flex h-8 items-center border border-x-0 border-solid border-gray-580 bg-black">
                                        <div
                                            className="text-xs flex-1 font-semibold py-0 pl-3 whitespace-nowrap overflow-hidden text-ellipsis text-gray-900">Price</div>
                                        <div
                                            className="text-xs flex-1 font-semibold py-0 pl-3 whitespace-nowrap overflow-hidden text-ellipsis text-gray-900">My Deposit</div>
                                        <div
                                            className="text-xs flex-1 font-semibold py-0 pl-3 whitespace-nowrap overflow-hidden text-ellipsis text-gray-900">Deposit Token</div>
                                    </div>
                                    <div className="h-[396px] w-full overflow-auto">
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="flex-col hidden sm:block">
                                <div className="mb-3 mt-[20px] text-xs text-gray-100 sm:mt-[30px] sm:text-sm">Legend</div>
                                <div className="flex flex-col gap-2 sm:gap-8 sm:flex-row">
                                    <div className="flex items-center gap-2">
                                        <div className="inline-block size-3 min-h-3 min-w-3 rounded-sm bg-green-100"></div>
                                        <div className="text-sm leading-6 text-gray-900">Interest Earning</div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="inline-block size-3 min-h-3 min-w-3 rounded-sm bg-lavender-100"></div>
                                        <div className="text-sm leading-6 text-gray-900">Non-Interest Earning</div>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <div className="inline-block size-3 min-h-3 min-w-3 rounded-sm bg-red-400"></div>
                                        <div className="text-sm leading-6 text-gray-900">Frozen</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ml-0 flex flex-1 flex-col gap-5 sm:ml-9">
                            <div
                                className="no-scrollbar w-full overflow-x-auto border-b border-gray-200 text-center text-base text-gray-500">
                                <ul className="flex flex-nowrap gap-6 justify-center">
                                    <li className="uppercase">
                                        <a
                                            className="flex justify-center py-4 border-b-2 cursor-pointer font-semibold text-primary-500 hover:text-primary-500 hover:border-primary-500 border-primary-500 rounded-t-lg active w-[130px] lg:w-[180px]">Deposit
                                        </a>
                                    </li>
                                    <li className="uppercase">
                                        <a
                                            className="flex justify-center py-4 border-b-2 rounded-t-lg text-white hover:text-gray-600 hover:border-gray-300 cursor-pointer font-normal border-transparent w-[130px] lg:w-[180px]">Withdraw
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <form className="ml-0 flex flex-1 flex-col gap-5 sm:ml-9">
                                <div className="flex flex-col">
                                    <fieldset className="flex flex-col gap-2">
                                        <div className="flex justify-between text-sm">
                                            <label className="text-gray-100">Amount</label>
                                            <div className="flex gap-2 text-gray-400">
                                                <span>Wallet Bal:</span>
                                                <button type="button" className="font-semibold text-ajna-ghost hover:underline">0.0 WETH</button>
                                            </div>
                                        </div>
                                        <div
                                            className="transition-all rounded-2xl text-left relative inline-block border-none px-0 text-gray-100 bg-transparent w-full">
                                            <div className="relative">
                                                <input
                                                    type="text"
                                                    className="transition-all bg-transparent w-full rounded-xl outline-none text-white font-bold border border-gray-560 focus:border-primary-500 placeholder-transparent px-4 py-4 pr-11 text-xl"
                                                    required=""
                                                    // control="[object Object]"
                                                    name="quoteAmount"
                                                    value="0"
                                                    inputmode="numeric"/>
                                                    <span className="absolute right-4 top-1/2 min-h-6 -translate-y-1/2 ">
                                                        <div
                                                            className="flex items-center gap-2 rounded-3xl bg-gray-580 px-2 py-1 font-semibold text-gray-400">
                                                            <div className="relative w-[24px] h-[24px]">
                                                                <img
                                                                    className="size-full rounded-full bg-white"
                                                                    src="https://assets.smold.app/api/token/1/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo-128.png"
                                                                    width="100%"
                                                                    height="100%"
                                                                    alt="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"/></div>
                                                                <span>WETH</span>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </fieldset>
                                            <span className="mt-8 text-sm text-gray-700">Price selected</span>
                                            <span className="mt-3 text-2xl text-gray-900 undefined">1.144151850726659798</span>
                                            <div
                                                className="flex h-10 w-full items-center justify-between rounded-xl border border-gray-560 mt-3">
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="ml-3 cursor-pointer">
                                                    <path d="M5 12h14" stroke="#fff" strokeWidth="1.5" strokeLinecap="round"></path>
                                                </svg>
                                                <span className="select-none text-base font-bold text-gray-900">1.144</span>
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 25 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="mr-3 cursor-pointer">
                                                    <path d="M12.5 5V19" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                                                    <path
                                                        d="M5.5 12C10.1667 12 19.5 12 19.5 12"
                                                        stroke="white"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"></path>
                                                </svg>
                                            </div>
                                        </div>
                                        <button
                                            className="button_bg hover:text-white cursor-pointer flex font-bold gap-3 items-center justify-center rounded-4xl text-center transition-all border-transparent text-white bg-ajna-primary hover:bg-ajna-primary-accent px-6 py-3.5 text-md"
                                            type="button">
                                            <span className="w-[18px] h-auto">
                                                <svg
                                                    width="100%"
                                                    height="100%"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M12 15.575a1.1 1.1 0 0 1-.375-.063.871.871 0 0 1-.325-.212l-3.6-3.6a.948.948 0 0 1-.275-.7c0-.283.092-.517.275-.7.183-.183.42-.28.712-.288.292-.008.53.08.713.263L11 12.15V5c0-.283.096-.521.288-.713A.967.967 0 0 1 12 4a.97.97 0 0 1 .713.287A.97.97 0 0 1 13 5v7.15l1.875-1.875c.183-.183.421-.271.713-.263.291.009.529.105.712.288a.948.948 0 0 1 .275.7.948.948 0 0 1-.275.7l-3.6 3.6c-.1.1-.208.17-.325.212a1.1 1.1 0 0 1-.375.063ZM6 20c-.55 0-1.02-.196-1.412-.587A1.927 1.927 0 0 1 4 18v-2a.97.97 0 0 1 .287-.713A.97.97 0 0 1 5 15a.97.97 0 0 1 .713.287A.97.97 0 0 1 6 16v2h12v-2c0-.283.096-.521.288-.713A.967.967 0 0 1 19 15c.283 0 .52.096.712.287.192.192.288.43.288.713v2c0 .55-.196 1.021-.587 1.413A1.928 1.928 0 0 1 18 20H6Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <span className="text-center">Lend Quote</span>
                                        </button>
                                        <button
                                            className="border-2 cursor-pointer flex font-bold gap-3 items-center justify-center rounded-4xl text-center transition-all bg-transparent hover:bg-ajna-ghost border-ajna-ghost text-white px-6 py-2.5 text-md"
                                            type="button">
                                            <span className="text-center">Go to Lending Position</span>
                                        </button>
                                        <div className="mb-8 mt-10 flex justify-between text-sm">
                                            <span className=" text-gray-700">My Total Deposit</span>
                                            <span>0.0 WETH</span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div></div>
                    </div>

                )
}

export default Index