import React, { useState } from 'react'
import { CiSearch } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { TfiViewList } from "react-icons/tfi";
import { motion, AnimatePresence } from 'framer-motion';




function PoolsMenu() {
    const [tokenId, setTokenId] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [filters, setFilters] = useState(false);

    const filterHandler = () => {
        setFilters(!filters);
    }



    return ( <> <div className="my-3 flex grow p-3 md:my-6 md:px-0">
        <div className="flex grow flex-col gap-2 sm:gap-4">
            <div className="flex items-center justify-between gap-4">
                <h1
                    className="whitespace-nowrap font-syncopate text-xl font-bold text-white uppercase leading-[48px] md:text-2xl">Top Pools</h1>
                <div className="flex h-[40px] w-full gap-4 sm:w-auto">
                    <label
                        className="flex w-full items-center rounded-3xl border border-gray-580 bg-gray-596 px-3 sm:w-auto ">
                        <CiSearch  className='text-[#AEAFC2]  mx-2 text-lg'/>
                        <input
                            className="w-[100px] text-white border-none bg-inherit text-sm sm:w-[200px] md:w-[350px] focus:outline-none focus:border-none"
                            placeholder="Find pools"
                            type="text"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </label>
                    <div className="flex cursor-pointer items-center" >
                        <span className="select-none whitespace-nowrap text-sm text-white" onClick={filterHandler}>NFT filter</span>
                        <MdKeyboardArrowDown className={`text-white text-xl transition-all duration-300 ease-in-out transform ${!filters ? "rotate-0": "rotate-180"}`} onClick={filterHandler}/>
                    </div>
                </div>
                <button className="flex items-center justify-center">
                    <TfiViewList className='text-white text-xl'/>
                </button>
            </div>
            <div className="flex w-full sm:hidden">
                <div className="flex cursor-pointer items-center">
                    <span className="select-none whitespace-nowrap text-sm text-white" onClick={filterHandler}>NFT filter</span>
                    <MdKeyboardArrowDown className={`text-white text-xl transition-all duration-300 ease-in-out transform ${!filters ? "rotate-0": "rotate-180"}`} onClick={filterHandler}/>
                </div>
            </div>
        </div>
    </div> 
    {filters && (
        <AnimatePresence>
        <motion.div
            key="filters"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }} 
            className = "flex justify-start sm:justify-center " > 
            <div className="flex h-[48px] w-auto items-center sm:w-[350px] ">
            <label className="mr-5 flex cursor-pointer items-center gap-2 pl-0 sm:mr-9 sm:pl-4">
                <input
                    className="w-5 h-5 border-2 border-gray-450 bg-transparent rounded-sm checked:border-primary-500 checked:bg-primary-500 disabled:border-ajna-disabled-2 disabled:bg-ajna-disabled indeterminate:bg-[url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 16 16%27%3e%3cpath stroke=%27%230177FB%27 d=%27M4 8h8%27/%3e%3c/svg%3e')] indeterminate:hover:bg-[url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 16 16%27%3e%3cpath stroke=%27currentColor%27 d=%27M4 8h8%27/%3e%3c/svg%3e')] indeterminate:disabled:bg-[url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 fill=%27none%27 viewBox=%270 0 16 16%27%3e%3cpath stroke=%27currentColor%27 strokeWidth=%272%27 d=%27M4 8h8%27/%3e%3c/svg%3e')]"
                    id=":r14:"
                    type="checkbox"/>
                    <span className="select-none whitespace-nowrap text-sm text-white">Show NFT pools</span>
                </label>
                <input
                    className="flex w-full rounded-xl border border-gray-580 bg-gray-596 px-4 py-3 text-white text-sm"
                    placeholder="Enter Token ID"
                    type="text"
                    value={tokenId}
                    inputMode="numeric"
                    aria-label="Token ID"
                    onChange={(e) => setTokenId(e.target.value)}
                    />
            </div>
        </motion.div>
        </AnimatePresence>
    )}
    </>)
}

export default PoolsMenu