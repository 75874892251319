import React from 'react'

import * as Icons from 'react-icons/bs'
import {images} from '../../assets'

function Footer() {
    return ( 
    <> 
        <footer className="flex w-full justify-center p-4 text-xs text-gray-300">
            <p>© 2024
                <a
                    className="text-ajna-primary"
                    href="https://builtby.mom/"
                    target="_blank"
                    rel="nofollow noreferrer"> Built by DefiConnect</a>
            </p>
        </footer> 
    </>
  )
}

export default Footer