export const navItems = [
  {
    id: 1,
    title: "Markets",
    path: "#markets",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  // {
  //   id: 2,
  //   title: "Grants",
  //   path: "#grants",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  // {
  //   id: 3,
  //   title: "Roadmap",
  //   path: "#roadmap",
  //   nName: "nav-item",
  //   sName: "sidebar-item"
  // },
  {
    id: 4,
    title: "FAQ",
    path: "#faq",
    nName: "nav-item",
    sName: "sidebar-item"
  },
  {
    id: 6,
    title: "Info",
    path: "#info",
    nName: "nav-item",
    sName: "sidebar-item"
  },
];
