
import React from 'react';
import { BanditContextProvider, Campaign } from '@bandit-network/react';



const BanditCampaign = ({ isOpen, onClose }) => {
  return (
    <BanditContextProvider apiKey={`ac2ad2eec4d44bb6820e463bf231a53e`} cluster="mainnet">
      <Campaign
        campaignId={529}
        displayMode="modal"
        isOpen={isOpen}
        onClose={onClose}
      />
    </BanditContextProvider>
  );
};

export default BanditCampaign;
