import React from 'react'
import DetailsCrumb from './sections/DetailsCrumb'
import DetailsHeader from './sections/DetailsHeader'
import DetailsTitle from './sections/DetailsTitle'
import LeftGrid from './sections/LeftGrid'
import RightGrid from './sections/RightGrid'

function Index() {
  return (
    <>
        <DetailsCrumb/>
        <DetailsTitle/>
        <DetailsHeader/>
        <div className='mt-5 flex flex-col gap-5 lg:mt-8 lg:flex-row lg:gap-10'>
            <LeftGrid/>
            <RightGrid/>
        </div>
    </>
  )
}

export default Index