import React, { useState } from 'react'

function Flyer() {
    const [flyer, setFlyer] = useState(true)
    return (
        <div className="mb-4 md:mx-auto md:max-w-[1200px]">
            {flyer && (
                <div
                className="bg-top-notification relative flex flex-col justify-center gap-2 rounded-lg p-4 text-white">
                <b>Hello</b>
                <small className="text-sm">Deficonnect is an exciting and quite possibly revolutionary new
                    DeFi primative. But like all new protocols, users should proceed with caution
                    and make sure they understand the code and smart contracts they are interacting
                    with. To learn more about Deficonnect check out the <span> </span>
                    <a
                        href="/"
                        target="_blank"
                        className="cursor-pointer underline"
                        rel="noreferrer">white paper</a>
                    <span> </span> or the
                    <a
                        href="/"
                        target="_blank"
                        className="cursor-pointer underline"
                        rel="noreferrer">ELI5</a>.</small>
                <div className="absolute right-2 top-2 font-bold">
                    <button className="p-1" onClick={() => setFlyer(!flyer)}>
                        <b>⛌</b>
                    </button>
                </div>
            </div>
            )}
        </div>
    )
}

export default Flyer