import React from 'react'

function RightGrid() {
    return (
        <div className="col-span-7 md:col-span-5 rounded-3xl bg-gray-595 !text-white">
            <div className="mb-4 flex flex-col items-center gap-2 p-6">
                <h2 className="text-3xl text-white" title="0.0">
                    <b>0.0</b>
                    <span className="text-gray-700">WETH</span>
                </h2>
                <span className="text-sm font-semibold text-gray-700">Current debt</span>
            </div>
            <div
                className="no-scrollbar w-full overflow-x-auto border-none md:border-b border-gray-200 text-center text-base text-gray-500">
                <ul className="flex flex-nowrap gap-6 justify-center">
                    <li className="uppercase">
                        <button
                            className="flex justify-center py-4 border-b-2 cursor-pointer font-semibold text-primary-500 hover:text-primary-500 hover:border-primary-500 border-primary-500 rounded-t-lg active w-[130px] lg:w-[180px]">Borrow
                        </button>
                    </li>
                    <li className="uppercase">
                        <button
                            className="flex justify-center py-4 border-b-2 rounded-t-lg text-white hover:text-gray-600 hover:border-gray-300 cursor-pointer font-normal border-transparent w-[130px] lg:w-[180px]">Repay
                        </button>
                    </li>
                </ul>
            </div>
            <form className="flex flex-col gap-6 p-8">
                <fieldset className="flex flex-col gap-2">
                    <div className="flex justify-between text-sm">
                        <label className="text-gray-100">Collateral to Pledge</label>
                        <div className="flex gap-2 text-gray-400">
                            <span>Wallet Bal</span>
                            <button type="button" className="font-semibold text-ajna-ghost hover:underline">0.0 wstETH</button>
                        </div>
                    </div>
                    <div
                        className="transition-all rounded-2xl text-left relative inline-block border-none px-0 text-gray-100 bg-transparent w-full">
                        <div className="relative">
                            <input
                                type="text"
                                className="transition-all bg-transparent w-full rounded-xl outline-none text-white font-bold border border-gray-560 focus:border-primary-500 placeholder-transparent px-4 py-4 pr-11 text-xl"
                                // control="[object Object]"
                                name="collateralAmount"
                                value="0"
                                inputmode="numeric"/>
                                <span className="absolute right-4 top-1/2 min-h-6 -translate-y-1/2 ">
                                    <div
                                        className="flex items-center gap-2 rounded-3xl bg-gray-580 px-2 py-1 font-semibold text-gray-400">
                                        <div className="relative w-[24px] h-[24px]">
                                            <img
                                                className="size-full rounded-full bg-white"
                                                src="https://assets.smold.app/api/token/1/0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0/logo-128.png"
                                                width="100%"
                                                height="100%"
                                                alt="0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0"/></div>
                                            <span>wstETH</span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </fieldset>
                        <fieldset className="flex flex-col gap-2">
                            <label className="text-sm text-gray-100">Borrow Amount</label>
                            <div
                                className="transition-all rounded-2xl text-left relative inline-block border-none px-0 text-gray-100 bg-transparent w-full">
                                <div className="relative">
                                    <input
                                        type="text"
                                        className="transition-all bg-transparent w-full rounded-xl outline-none text-white font-bold border border-gray-560 focus:border-primary-500 placeholder-transparent px-4 py-4 pr-11 text-xl"
                                        control="[object Object]"
                                        name="debtAmount"
                                        value="0"
                                        inputmode="numeric"/>
                                        <span className="absolute right-4 top-1/2 min-h-6 -translate-y-1/2 ">
                                            <div
                                                className="flex items-center gap-2 rounded-3xl bg-gray-580 px-2 py-1 font-semibold text-gray-400">
                                                <div className="relative w-[24px] h-[24px]">
                                                    <img
                                                        className="size-full rounded-full bg-white"
                                                        src="https://assets.smold.app/api/token/1/0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2/logo-128.png"
                                                        width="100%"
                                                        height="100%"
                                                        alt="0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"/></div>
                                                    <span>WETH</span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </fieldset>
                                <button
                                    className="button_bg text-white cursor-pointer flex font-bold gap-3 items-center justify-center rounded-4xl text-center transition-all border-transparent  px-6 py-2.5 text-md w-full"
                                    type="submit">
                                    <span className="text-center">Pledge &amp; draw debt</span>
                                </button>
                                <footer className="mt-8 flex flex-col gap-6">
                                    <div className="flex flex-col gap-1 text-sm">
                                        <span className="text-gray-100">Liquidation price</span>
                                        <span className="text-gray-900" title="0.0">0.0 WETH</span>
                                    </div>
                                    <div className="flex flex-col gap-1 text-sm">
                                        <span className="text-gray-100">Lowest Utilization Price</span>
                                        <span className="text-gray-900" title="0.0">N/A WETH</span>
                                    </div>
                                </footer>
                            </form>
                        </div>
    )
}

export default RightGrid